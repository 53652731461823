'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _classlist = require('./helpers/classlist');

var _classlist2 = _interopRequireDefault(_classlist);

var _UiIconButton = require('./UiIconButton.vue');

var _UiIconButton2 = _interopRequireDefault(_UiIconButton);

var _UiButton = require('./UiButton.vue');

var _UiButton2 = _interopRequireDefault(_UiButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'ui-modal',

    props: {
        show: {
            type: Boolean,
            required: true,
            twoWay: true
        },
        type: {
            type: String,
            default: 'normal', // 'small', 'normal', or 'large'
            coerce: function coerce(type) {
                return 'ui-modal-' + type;
            }
        },
        header: {
            type: String,
            default: 'UiModal Header'
        },
        body: {
            type: String,
            default: 'UiModal body'
        },
        role: {
            type: String,
            default: 'dialog' // 'dialog' or 'alertdialog'
        },
        transition: {
            type: String,
            default: 'ui-modal-scale' // 'ui-modal-scale', or 'ui-modal-fade'
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        dismissible: {
            type: Boolean,
            default: true
        },
        backdropDismissible: {
            type: Boolean,
            default: true
        }
    },

    data: function data() {
        return {
            lastFocussedElement: null
        };
    },


    watch: {
        show: function show() {
            var _this = this;

            this.$nextTick(function () {
                if (_this.show) {
                    _this.opened();
                } else {
                    _this.closed();
                }
            });
        }
    },

    beforeDestroy: function beforeDestroy() {
        if (this.show) {
            this.tearDown();
        }
    },


    methods: {
        close: function close(e) {
            if (!this.dismissible) {
                return;
            }

            // Make sure the element clicked was the modal mask and not a child
            // whose click event has bubbled up
            if (e.currentTarget === this.$els.modalMask && e.target !== e.currentTarget) {
                return;
            }

            // Don't close if this event was triggered by the modal mask
            // and this.backdropDismissible is false
            if (e.currentTarget === this.$els.modalMask && !this.backdropDismissible) {
                return;
            }

            this.show = false;
        },
        opened: function opened() {
            this.lastFocussedElement = document.activeElement;
            this.$els.modalContainer.focus();

            _classlist2.default.add(document.body, 'ui-modal-open');

            document.addEventListener('focus', this.restrictFocus, true);

            this.$dispatch('opened');
        },
        closed: function closed() {
            this.tearDown();
            this.$dispatch('closed');
        },
        redirectFocus: function redirectFocus(e) {
            e.stopPropagation();

            this.$els.modalContainer.focus();
        },
        restrictFocus: function restrictFocus(e) {
            if (!this.$els.modalContainer.contains(e.target)) {
                e.stopPropagation();
                this.$els.modalContainer.focus();
            }
        },
        tearDown: function tearDown() {
            _classlist2.default.remove(document.body, 'ui-modal-open');

            document.removeEventListener('focus', this.restrictFocus, true);

            if (this.lastFocussedElement) {
                this.lastFocussedElement.focus();
            }
        },
        transitionEnd: function transitionEnd() {
            if (this.show) {
                this.$dispatch('revealed');
            } else {
                this.$dispatch('hidden');
            }
        }
    },

    components: {
        UiIconButton: _UiIconButton2.default,
        UiButton: _UiButton2.default
    }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-modal ui-modal-mask\" v-show=\"show\" :transition=\"transition\" :class=\"[type]\" :role=\"role\" @transitionend=\"transitionEnd | debounce 100\">\n    <div class=\"ui-modal-wrapper\" @click=\"close\" v-el:modal-mask=\"\">\n        <div class=\"ui-modal-container\" tabindex=\"-1\" @keydown.esc=\"close\" v-el:modal-container=\"\">\n            <div class=\"ui-modal-header\">\n                <slot name=\"header\">\n                    <h1 v-text=\"header\" class=\"ui-modal-header-text\"></h1>\n                </slot>\n\n                <ui-icon-button type=\"clear\" icon=\"\" class=\"ui-modal-close-button\" @click=\"close\" :disabled=\"!dismissible\" v-if=\"showCloseButton\" v-el:close-button=\"\"></ui-icon-button>\n            </div>\n\n            <div class=\"ui-modal-body\">\n                <slot>\n                    <div v-text=\"body\"></div>\n                </slot>\n            </div>\n\n            <div class=\"ui-modal-footer\" v-if=\"!hideFooter\">\n                <slot name=\"footer\">\n                    <ui-button @click=\"close\" v-if=\"dismissible\">Close</ui-button>\n                </slot>\n            </div>\n\n            <div class=\"focus-redirector\" @focus=\"redirectFocus\" tabindex=\"0\"></div>\n        </div>\n    </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-28453304", module.exports)
  } else {
    hotAPI.update("_v-28453304", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}