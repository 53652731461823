'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _globals = require('../mixins/globals');

var _globals2 = _interopRequireDefault(_globals);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'LogsView',
  props: ['item'],
  mixins: [_globals2.default],
  data: function data() {
    return {};
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<tr :id=\"item.log_uid\">\n  <td>{{item.timestamp | formatFull}}({{item.timestamp}})</td>\n  <td>{{item.stacktrace}}</td>\n  <td>{{item.text}}</td>\n</tr>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-321003c9", module.exports)
  } else {
    hotAPI.update("_v-321003c9", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}