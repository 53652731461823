'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'ui-progress-circular',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'indeterminate' // 'indeterminate' or 'determinate'
    },
    color: {
      type: String,
      default: 'primary' // 'primary', 'accent', multi-color', 'black', or 'white'
    },
    value: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 32
    },
    stroke: Number,
    autoStroke: {
      type: Boolean,
      default: true
    },
    disableTransition: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    strokeDashArray: function strokeDashArray() {
      var circumference = 2 * Math.PI * this.radius;
      return Math.round(circumference * 1000) / 1000;
    },
    strokeDashOffset: function strokeDashOffset() {
      var value = this.moderateValue(this.value);
      var circumference = 2 * Math.PI * this.radius;

      return (100 - value) / 100 * circumference;
    },
    radius: function radius() {
      return (this.size - this.stroke) / 2;
    }
  },
  created: function created() {
    if (!this.stroke) {
      if (this.autoStroke) {
        this.stroke = parseInt(this.size / 8, 10);
      } else {
        this.stroke = 4;
      }
    }
  },

  methods: {
    moderateValue: function moderateValue(value) {
      if (isNaN(value) || value < 0) {
        return 0;
      }
      if (value > 100) {
        return 100;
      }
      return value;
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-progress-circular\" :style=\"{ 'width': size + 'px', 'height': size + 'px' }\" v-show=\"show\" :transition=\"disableTransition ? null : 'ui-progress-circular-toggle'\">\n    <svg class=\"ui-progress-circular-determinate\" :width=\"size\" :height=\"size\" role=\"progressbar\" :aria-valuemin=\"0\" :aria-valuemax=\"100\" :aria-valuenow=\"value\" v-if=\"type === 'determinate'\">\n        <circle class=\"ui-progress-circular-determinate-path\" :class=\"[color]\" :r=\"radius\" :cx=\"size / 2\" :cy=\"size / 2\" fill=\"transparent\" :stroke-dasharray=\"strokeDashArray\" stroke-dashoffset=\"0\" :style=\"{ 'stroke-dashoffset': strokeDashOffset, 'stroke-width': stroke }\"></circle>\n    </svg>\n\n    <svg class=\"ui-progress-circular-indeterminate\" viewBox=\"25 25 50 50\" role=\"progressbar\" :aria-valuemin=\"0\" :aria-valuemax=\"100\" v-else=\"\">\n        <circle class=\"ui-progress-circular-indeterminate-path\" :class=\"[color]\" cx=\"50\" cy=\"50\" r=\"20\" fill=\"none\" stroke-miterlimit=\"10\" :stroke-width=\"stroke\">\n    </circle></svg>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-928e51aa", module.exports)
  } else {
    hotAPI.update("_v-928e51aa", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}