'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'ui-icon',

  props: {
    icon: {
      type: String,
      required: true
    },
    removeText: {
      type: Boolean,
      default: false
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<i class=\"ui-icon material-icons\" :class=\"[icon]\" v-text=\"removeText ? null : icon\" aria-hidden=\"true\"></i>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-d55a5090", module.exports)
  } else {
    hotAPI.update("_v-d55a5090", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}