'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _UiIcon = require('./UiIcon.vue');

var _UiIcon2 = _interopRequireDefault(_UiIcon);

var _UiMenu = require('./UiMenu.vue');

var _UiMenu2 = _interopRequireDefault(_UiMenu);

var _UiProgressCircular = require('./UiProgressCircular.vue');

var _UiProgressCircular2 = _interopRequireDefault(_UiProgressCircular);

var _disabled = require('./directives/disabled');

var _disabled2 = _interopRequireDefault(_disabled);

var _HasDropdown = require('./mixins/HasDropdown');

var _HasDropdown2 = _interopRequireDefault(_HasDropdown);

var _ShowsRippleInk = require('./mixins/ShowsRippleInk');

var _ShowsRippleInk2 = _interopRequireDefault(_ShowsRippleInk);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-icon-button',

  props: {
    type: {
      type: String,
      default: 'normal', // 'normal' or 'flat' or 'clear'
      coerce: function coerce(type) {
        return 'ui-icon-button-' + type;
      }
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    color: {
      type: String,
      default: 'default', // 'default', 'primary', 'accent', 'success', 'warning', or 'danger'
      coerce: function coerce(color) {
        return 'color-' + color;
      }
    },
    icon: {
      type: String,
      required: true
    },
    ariaLabel: String,
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    styleClasses: function styleClasses() {
      var classes = [this.type, this.color];
      if (this.hasDropdown) {
        classes.push('ui-dropdown');
      }
      return classes;
    },
    spinnerColor: function spinnerColor() {
      if (this.color === 'color-default' || this.color === 'color-black') {
        return 'black';
      }
      return 'white';
    }
  },

  components: {
    UiIcon: _UiIcon2.default,
    UiMenu: _UiMenu2.default,
    UiProgressCircular: _UiProgressCircular2.default
  },
  mixins: [_HasDropdown2.default, _ShowsRippleInk2.default],
  directives: {
    disabled: _disabled2.default
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<button class=\"ui-icon-button\" :class=\"styleClasses\" :aria-label=\"ariaLabel || tooltip\" :type=\"buttonType\" v-disabled=\"disabled || loading\" v-el:button=\"\">\n  <ui-icon class=\"ui-icon-button-icon\" :icon=\"icon\" v-show=\"!loading\"></ui-icon>\n\n  <ui-progress-circular class=\"ui-icon-button-spinner\" :color=\"spinnerColor\" :size=\"24\" :stroke=\"4.5\" disable-transition=\"\" v-show=\"loading\"></ui-progress-circular>\n\n  <ui-ripple-ink v-if=\"!hideRippleInk &amp;&amp; !disabled\" :trigger=\"$els.button\"></ui-ripple-ink>\n\n  <ui-menu class=\"ui-button-dropdown-menu\" :trigger=\"$els.button\" :options=\"menuOptions\" :show-icons=\"showMenuIcons\" :show-secondary-text=\"showMenuSecondaryText\" :open-on=\"openDropdownOn\" @option-selected=\"menuOptionSelect\" :dropdown-position=\"dropdownPosition\" v-if=\"hasDropdownMenu\"></ui-menu>\n</button>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-27b39a0a", module.exports)
  } else {
    hotAPI.update("_v-27b39a0a", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}