'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _globals = require('../mixins/globals');

var _globals2 = _interopRequireDefault(_globals);

var _UiAlert = require('../material/UiAlert.vue');

var _UiAlert2 = _interopRequireDefault(_UiAlert);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'Usuarios',
  mixins: [_globals2.default],
  components: [_UiAlert2.default],
  data: function data() {
    return {
      email_eliminar: '',
      eliminar_loading: false,
      eliminar_disabled: false,
      alertEliminarCuenta: {
        type: 'successful',
        message: '',
        show: false
      }
    };
  },
  methods: {
    sendEliminarCuenta: function sendEliminarCuenta() {
      var _this = this;
      this.$http.post('/delete_account', {
        email: _this.email_eliminar
      }).then(function (response) {
        console.log(response);
        _this.alertEliminarCuenta.type = 'successful';
        _this.alertEliminarCuenta.message = 'Usuario eliminado correctamente.';
        _this.alertEliminarCuenta.show = true;
      }).catch(function (error) {
        console.error(error);
        _this.alertEliminarCuenta.type = 'error';
        _this.alertEliminarCuenta.message = 'Error al eliminar el usuario.';
        _this.alertEliminarCuenta.show = true;
      });
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"bgTransition\">\n  <div class=\"header-section h_contact\">\n    <h2 v-if=\"titleShow\" transition=\"slideLeft\">Usuarios</h2>\n  </div>\n  <section class=\"section-users\">\n    <div class=\"width-users clearfix\">\n      <ui-alert :type=\"alertEliminarCuenta.type\" :show=\"alertEliminarCuenta.show\" :dismissible=\"false\">{{ alertEliminarCuenta.message }}</ui-alert>\n      <h4 class=\"title_block\">Elminar Cuenta</h4>\n      <ui-textbox type=\"text\" name=\"email\" placeholder=\"Email de usaurio\" :value.sync=\"email_eliminar\" autocomplete=\"off\"></ui-textbox>\n      <div style=\"color: white\">\n        <ui-button @click=\"sendEliminarCuenta()\" text=\"Mostrar\" color=\"admin-primario\" :loading=\"eliminar_loading\" :disabled=\"eliminar_disabled\"></ui-button>\n      </div>\n    </div>\n  </section>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-a5d23c46", module.exports)
  } else {
    hotAPI.update("_v-a5d23c46", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}