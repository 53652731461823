import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import VueDND from 'awe-dnd'
import StickerEditModule from './pages/StickerEdit.vue'
import StickerListModule from './pages/StickersList.vue'
import CategoryEditModule from './pages/CategoryEdit.vue'
import CategoryListModule from './pages/CategoryList.vue'
import BaseModule from './pages/Base.vue'
import DeviceViewModule from './pages/DevicesView.vue'
import DeviceListModule from './pages/VersionList.vue'
import LogsViewModule from './pages/LogsView.vue'
import VersionListModule from './pages/VersionList.vue'
import DevicesHistoryModule from './pages/DevicesHistory.vue'
import TrustedUsersModule from './pages/TrustedUsers.vue'
import FotaVersionsModule from './pages/FotaList.vue'
import FotaBuildsModule from './pages/FotaBuilds.vue'
import AppsModule from './pages/AppList.vue'
import CarrierUsers from './pages/CarrierUsers.vue'
import Carrier from './pages/Carrier.vue'
import DashboardViewModule from './pages/Dashboard.vue'
import DashboardiOSViewModule from './pages/DashboardiOs.vue'
import DashboardAndroidViewModule from './pages/DashboardAndroid.vue'
import RequiredNumbers from './pages/RequiredNumbers.vue'
import Usuarios from './pages/Usuarios.vue'
import CustomerSupport from './pages/CustomerSupport.vue'

Vue.use(VueDND)
Vue.use(VueRouter)
Vue.use(VueResource)
require('vue-scroll')
// Vue.use(infiniteScroll)

Vue.directive('click-outside', {
  priority: 700,
  bind () {
    let self  = this
    this.event = (event) => {
      self.vm.$emit(self.expression, event)
    }
    this.el.addEventListener('click', this.stopProp)
    document.body.addEventListener('click', this.event)
  },
  unbind () {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },
  stopProp (event) {
    event.stopPropagation()
  }
})

const router = new VueRouter({
  hashbang: true,
  history: false,
  root: '',
  mode: 'html5',
  transitionOnLoad: true,
  saveScrollPosition: false
})

router.map({
  '/': {
    name: 'base',
    component: BaseModule,
    subRoutes: {
      '/': {
        name: 'customer',
        component: CustomerSupport
      },
      '/dashboard_server_1': {
        name: 'dashboard_server_1',
        component: DashboardViewModule
      },
      '/dashboard_server_2': {
        name: 'dashboard_server_2',
        component: DashboardViewModule
      },
      '/dashboard_ios': {
        name: 'dashboard_ios',
        component: DashboardiOSViewModule
      },
      '/dashboard_android': {
        name: 'dashboard_android',
        component: DashboardAndroidViewModule
      },
      '/devices': {
        name: 'devices',
        component: DeviceViewModule
      },
      '/versiones': {
        name: 'versions',
        component: VersionListModule
      },
      '/logs': {
        name: 'logs',
        component: LogsViewModule
      },
      '/history': {
        name: 'history',
        component: DevicesHistoryModule
      },
      '/categorias': {
        name: 'categorias',
        component: CategoryListModule
      },
      '/categorias/edit/:id': {
        name: 'categoria-edit',
        component: CategoryEditModule
      },
      '/categorias/:id': {
        name: 'sticker',
        component: StickerListModule
      },
      '/categorias/:id/:stk': {
        name: 'sticker-edit',
        component: StickerEditModule
      },
      '/trusted_users': {
         name: 'trusted_users',
         component: TrustedUsersModule
      },
      '/fota_versions':{
        name: 'fota_versions',
        component: FotaVersionsModule
      },
      '/fota_builds':{
        name: 'fota_builds',
        component: FotaBuildsModule
      },
      '/apps':{
        name: 'apps',
        component: AppsModule
      },
      '/carrierusers': {
        name: 'carrierusers',
        component: CarrierUsers
      },
      '/carriers': {
        name: 'carriers',
        component: Carrier
      },
      '/required_numbers': {
        name: 'required_numbers',
        component: RequiredNumbers
      },
      '/users': {
        name: 'users',
        component: Usuarios
      },
      '/customersupport': {
        name: 'customersupport',
        component: CustomerSupport
      }
    }
  }
})

/*var App = Vue.extend({
  data() {
    return {
    }
  },
  methods: {

  }
});*/

router.start(App, 'App')
