'use strict';

Object.defineProperty(exports, "__esModule", {
   value: true
});

var _globals = require('../mixins/globals');

var _globals2 = _interopRequireDefault(_globals);

var _UiSelect = require('../material/UiSelect.vue');

var _UiSelect2 = _interopRequireDefault(_UiSelect);

var _AndaAdmLogs = require('../components/AndaAdmLogs.vue');

var _AndaAdmLogs2 = _interopRequireDefault(_AndaAdmLogs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
   mixins: [_globals2.default],
   components: {
      UiSelect: _UiSelect2.default,
      AndaAdminLogs: _AndaAdmLogs2.default
   },
   data: function data() {
      return {
         nuevo_correo: '',
         enviado: false,
         roles: [],
         carriers: [],
         rol_seleccionado: {},
         carrier_seleccionado: {},
         roles_tmp: [],
         carriers_tmp: []
      };
   },
   methods: {
      sendVerificationEmail: function sendVerificationEmail() {
         console.log(this.rol_seleccionado.value);
         this.$http.post('/carrier/signup', {
            signup_email: this.nuevo_correo,
            rol: this.rol_seleccionado.value,
            carrier_id: this.carrier_seleccionado.value
         }).then(function (response) {
            console.log(response);
            this.enviado = true;
         }).catch(function (err) {
            console.error(err);
         });
      },
      restartLayoutEnvio: function restartLayoutEnvio() {
         this.enviado = false;
         this.nuevo_correo = '';
      }
   },
   created: function created() {
      var _this = this;
      this.$http.post('/carrier').then(function (response) {
         console.log(response);
         _this.roles = response.body.roles;
         _this.carriers = response.body.carriers;
         _this.carriers_tmp = [];
         _this.roles_tmp = [];
         _this.roles.forEach(function (item) {
            _this.roles_tmp.push({ text: item.nombre, value: item.id });
         });
         _this.carriers.forEach(function (item) {
            _this.carriers_tmp.push({ text: item.nombre, value: item.id });
         });
      }).catch(function (err) {
         console.error(err);
      });
   }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"bgTransition\">\n   <div class=\"header-section h_contact\">\n      <h2 v-if=\"titleShow\" transition=\"slideLeft\">Carrier Users</h2>\n   </div>\n   <section class=\"section-users\">\n      <div class=\"width-users clearfix\">\n         <h4>Crear un nuevo usuario para un carrier</h4>\n         <div v-if=\"!enviado\">\n            <ui-textbox type=\"text\" placeholder=\"Correo de nuevo usuario\" :value.sync=\"nuevo_correo\" autocomplete=\"off\" name=\"email\" validation-rules=\"required\"></ui-textbox>\n            <ui-select :options=\"roles_tmp\" placeholder=\"Ingrese el rol del usuario\" name=\"nombre\" :value.sync=\"rol_seleccionado\"></ui-select>\n            <div style=\"padding-top: 20px\">\n               <ui-select :options=\"carriers_tmp\" placeholder=\"Ingrese Telco a asignar\" name=\"nombreTelco\" :value.sync=\"carrier_seleccionado\"></ui-select>\n            </div>\n         </div>\n         <div v-if=\"!enviado\" style=\"padding-top: 20px\">\n            <div style=\"color: white\">\n               <ui-button text=\"Enviar Correo\" color=\"admin-primario\" v-on:click=\"sendVerificationEmail\"></ui-button>\n            </div>\n         </div>\n         <div v-else=\"\">\n            <div style=\"color: white\">\n               <ui-button text=\"Enviar Otro\" color=\"admin-primario\" v-on:click=\"restartLayoutEnvio\"></ui-button>\n            </div>\n         </div>\n      </div>\n      <br>\n      <anda-admin-logs></anda-admin-logs>\n   </section>\n</div> \n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-007dfb48", module.exports)
  } else {
    hotAPI.update("_v-007dfb48", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}