'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CONFIG = {
  HOUR_TOKENS: ['HH', 'H', 'hh', 'h'],
  MINUTE_TOKENS: ['mm'],
  // SECOND_TOKENS: ['ss', 's'],
  APM_TOKENS: ['A', 'a']
};
exports.default = {
  name: 'UiTimePicker',
  props: {
    timeValue: { type: Object, twoWay: true },
    type: { type: String },
    // hideClearButton: {type: Boolean},
    color: { type: String },
    format: { type: String
      // minuteInterval: {type: Number},
      // secondInterval: {type: Number}
    } },
  data: function data() {
    return {
      hours: [],
      minutes: [],
      // seconds: [],
      // apms: [],
      showDropdown: false,
      muteWatch: false,
      hourType: 'HH',
      minuteType: 'mm',
      secondType: '',
      apmType: '',
      hour: '',
      minute: '',
      second: '',
      apm: '',
      fullValues: undefined
    };
  },


  computed: {
    displayTime: function displayTime() {
      var formatString = String(this.format || 'HH:mm');
      if (this.hour) {
        formatString = formatString.replace(new RegExp(this.hourType, 'g'), this.hour);
      }
      if (this.minute) {
        formatString = formatString.replace(new RegExp(this.minuteType, 'g'), this.minute);
      }
      // if (this.second && this.secondType) {
      //   formatString = formatString.replace(new RegExp(this.secondType, 'g'), this.second)
      // }
      // if (this.apm && this.apmType) {
      //   formatString = formatString.replace(new RegExp(this.apmType, 'g'), this.apm)
      // }
      return formatString;
    }
    // showClearBtn () {
    //   if ((this.hour && this.hour !== '') || (this.minute && this.minute !== '')) {
    //     return true
    //   }
    //   return false
    // }

  },

  watch: {
    'format': 'renderFormat',
    // minuteInterval (newInteval) {
    //   this.renderList('minute', newInteval)
    // },
    // secondInterval (newInteval) {
    //   this.renderList('second', newInteval)
    // },
    'timeValue': 'readValues',
    'displayTime': 'fillValues'
  },
  events: {
    changeTimeValue: function changeTimeValue(type, data) {
      var _this = this;

      if (this.type === type) {
        this.$nextTick(function () {
          _this.select('hour', data.HH);
          _this.select('minute', data.mm);
          _this.$off('changeTimeValue');
        });
      }
    }
  },
  methods: {
    select: function select(type, value) {
      if (type === 'hour') {
        this.hour = value;
      } else if (type === 'minute') {
        this.minute = value;
      }
    },
    formatValue: function formatValue(type, i) {
      switch (type) {
        // case 'H':
        // case 'm':
        // case 's':
        //   return String(i)
        case 'HH':
        case 'mm':
        case 'ss':
          return i < 10 ? '0' + i : String(i);
        // case 'h':
        // case 'k':
        //   return String(i + 1)
        // case 'hh':
        // case 'kk':
        //   return (i + 1) < 10 ? `0${i + 1}` : String(i + 1)
        default:
          return '';
      }
    },
    checkAcceptingType: function checkAcceptingType(validValues, formatString, fallbackValue) {
      if (!validValues || !formatString || !formatString.length) {
        return '';
      }
      for (var i = 0; i < validValues.length; i++) {
        if (formatString.indexOf(validValues[i]) > -1) {
          return validValues[i];
        }
      }
      return fallbackValue || '';
    },
    renderFormat: function renderFormat(newFormat) {
      newFormat = newFormat || this.format;
      if (!newFormat || !newFormat.length) {
        newFormat = 'HH:mm';
      }

      this.hourType = this.checkAcceptingType(CONFIG.HOUR_TOKENS, newFormat, 'HH');
      this.minuteType = this.checkAcceptingType(CONFIG.MINUTE_TOKENS, newFormat, 'mm');
      // this.secondType = this.checkAcceptingType(CONFIG.SECOND_TOKENS, newFormat)
      // this.apmType = this.checkAcceptingType(CONFIG.APM_TOKENS, newFormat)

      this.renderHoursList();
      this.renderList('minute');

      if (this.secondType) {
        this.renderList('second');
      }

      // if (this.apmType) {
      //   this.renderApmList()
      // }

      var self = this;
      this.$nextTick(function () {
        self.readValues();
      });
    },
    renderHoursList: function renderHoursList() {
      var hoursCount = this.hourType === 'h' || this.hourType === 'hh' ? 12 : 24;
      this.hours = [];
      for (var i = 0; i < hoursCount; i++) {
        this.hours.push(this.formatValue(this.hourType, i));
      }
    },
    renderList: function renderList(listType, interval) {
      // if (listType === 'second') {
      //   interval = interval || this.secondInterval
      // } else if (listType === 'minute') {
      //   interval = interval || this.minuteInterval
      // } else {
      //   return
      // }
      // if (interval === 0) {
      //   interval = 60
      // } else if (interval > 60) {
      //   interval = 1
      // } else if (interval < 1) {
      //   interval = 1
      // } else if (!interval) {
      interval = 1;
      // }
      if (listType === 'minute') {
        this.minutes = [];
      }
      // else {
      //   this.seconds = []
      // }
      for (var i = 0; i < 60; i += interval) {
        if (listType === 'minute') {
          this.minutes.push(this.formatValue(this.minuteType, i));
        }
        // else {
        //   this.seconds.push(this.formatValue(this.secondType, i))
        // }
      }
    },

    // renderApmList () {
    //   this.apms = []
    //   if (!this.apmType) { return }
    //   this.apms = this.apmType === 'A' ? ['AM', 'PM'] : ['am', 'pm']
    // },
    readValues: function readValues() {
      if (!this.timeValue || this.muteWatch) {
        return;
      }

      var timeValue = JSON.parse((0, _stringify2.default)(this.timeValue || {}));
      var values = (0, _keys2.default)(timeValue);
      // console.log('timevalue', timeValue, values)
      if (values.length === 0) {
        return;
      }

      if (values.indexOf(this.hourType) > -1) {
        this.hour = timeValue[this.hourType];
      }

      if (values.indexOf(this.minuteType) > -1) {
        this.minute = timeValue[this.minuteType];
      }
      // if (values.indexOf(this.secondType) > -1) {
      //   this.second = timeValue[this.secondType]
      // } else {
      //   this.second = 0
      // }
      // if (values.indexOf(this.apmType) > -1) {
      //   this.apm = timeValue[this.apmType]
      // }
      this.fillValues();
    },
    fillValues: function fillValues() {
      var fullValues = {};

      var baseHour = this.hour;
      var baseHourType = this.hourType;

      var hourValue = baseHour || baseHour === 0 ? Number(baseHour) : '';
      var baseOnTwelveHours = this.isTwelveHours(baseHourType);
      var apmValue = baseOnTwelveHours && this.apm ? String(this.apm).toLowerCase() : false;

      CONFIG.HOUR_TOKENS.forEach(function (token) {
        if (token === baseHourType) {
          fullValues[token] = baseHour;
          return;
        }

        var value = void 0;
        // let apm
        switch (token) {
          case 'H':
          case 'HH':
            if (!String(hourValue).length) {
              fullValues[token] = '';
              return;
            } else if (baseOnTwelveHours) {
              if (apmValue === 'pm') {
                value = hourValue < 12 ? hourValue + 12 : hourValue;
              } else {
                value = hourValue % 12;
              }
            } else {
              value = hourValue % 24;
            }
            fullValues[token] = token === 'HH' && value < 10 ? '0' + value : String(value);
            break;
        }
      });

      if (this.minute || this.minute === 0) {
        var minuteValue = Number(this.minute);
        fullValues.m = String(minuteValue);
        fullValues.mm = minuteValue < 10 ? '0' + minuteValue : String(minuteValue);
      } else {
        fullValues.m = '';
        fullValues.mm = '';
      }
      this.fullValues = fullValues;
      this.updateTimeValue(fullValues);
      // this.$dispatch('vue-timepicker-update', fullValues)
    },
    updateTimeValue: function updateTimeValue(fullValues) {
      var _this2 = this;

      if (!this.timeValue) {
        // return the `fullValues` if `timeValue` is not set
        this.$dispatch('change', { data: fullValues });
        return;
      }

      this.muteWatch = true;

      var baseTimeValue = JSON.parse((0, _stringify2.default)(this.timeValue || {}));
      var timeValue = {};
      (0, _keys2.default)(baseTimeValue).forEach(function (key) {
        timeValue[key] = fullValues[key];
      });
      this.timeValue = timeValue;
      // console.log('timeValue', timeValue)
      // console.log('this.timeValue', this.timeValue)
      // const self = this
      this.$nextTick(function () {
        _this2.muteWatch = false;
        _this2.$dispatch('change', { data: _this2.timeValue });
      });
    },
    isTwelveHours: function isTwelveHours(token) {
      return token === 'h' || token === 'hh';
    },
    toggleDropdown: function toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    }
  },
  ready: function ready() {
    this.renderFormat();
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-time-picker\">\n  <input :class=\"'display-time ' + color\" v-model=\"displayTime\" @click.stop=\"toggleDropdown\" type=\"text\" readonly=\"\">\n  <div class=\"ui-time-picker-overlay\" v-if=\"showDropdown\" @click.stop=\"toggleDropdown\"></div>\n  <div class=\"dropdown\" v-show=\"showDropdown\">\n    <div class=\"select-list\">\n      <ul class=\"hours\">\n        <li class=\"hint\" v-text=\"hourType\"></li>\n        <li v-for=\"hr in hours\" v-text=\"hr\" :class=\"{active: hour === hr}\" @click=\"select('hour', hr)\"></li>\n      </ul>\n      <ul class=\"minutes\">\n        <li class=\"hint\" v-text=\"minuteType\"></li>\n        <li v-for=\"m in minutes\" v-text=\"m\" :class=\"{active: minute === m}\" @click=\"select('minute', m)\"></li>\n      </ul>\n      <!-- ul class=\"seconds\" v-if=\"secondType\">\n        <li class=\"hint\" v-text=\"secondType\"></li>\n        <li v-for=\"s in seconds\" v-text=\"s\" :class=\"{active: second === s}\" @click.stop=\"select('second', s)\"></li>\n      </ul>\n      <ul class=\"apms\" v-if=\"apmType\">\n        <li class=\"hint\" v-text=\"apmType\"></li>\n        <li v-for=\"a in apms\" v-text=\"a\" :class=\"{active: apm === a}\" @click.stop=\"select('apm', a)\"></li>\n      </ul -->\n    </div>\n  </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-6873e9eb", module.exports)
  } else {
    hotAPI.update("_v-6873e9eb", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}