'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _globals = require('../mixins/globals');

var _globals2 = _interopRequireDefault(_globals);

var _UiAlert = require('../material/UiAlert.vue');

var _UiAlert2 = _interopRequireDefault(_UiAlert);

var _UiIconButton = require('../material/UiIconButton.vue');

var _UiIconButton2 = _interopRequireDefault(_UiIconButton);

var _UiSelect = require('../material/UiSelect.vue');

var _UiSelect2 = _interopRequireDefault(_UiSelect);

var _UiFileUpload = require('../material/UiFileUpload.vue');

var _UiFileUpload2 = _interopRequireDefault(_UiFileUpload);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var category_type = [{ text: 'Estatico', value: 'static' }, { text: 'Animado', value: 'animated' }];
exports.default = {
  name: 'EditarCategoria',
  mixins: [_globals2.default],
  components: {
    UiAlert: _UiAlert2.default,
    UiIconButton: _UiIconButton2.default,
    UiSelect: _UiSelect2.default,
    UiFileUpload: _UiFileUpload2.default
  },
  route: {
    data: function data(transition) {
      var _id = transition.to.params.id;
      this.category_id = _id;
      this.fetchCategory(_id);
    },
    deactivate: function deactivate(transition) {
      transition.next();
    }
  },
  data: function data() {
    return {
      category_id: '',
      category: {},
      alertEdit: {
        show: false,
        type: 'success',
        messages: ''
      },
      category_image: null,
      category_type: category_type,
      tempType: null,
      responseCategory: false,
      disabledCancel: false,
      loadingEdit: false,
      disabledEdit: false,
      tempImage: null,
      flagEditImage: false
    };
  },

  watch: {
    'alertEdit.show': function alertEditShow(val, old) {
      var _this2 = this;

      if (val) {
        setTimeout(function () {
          _this2.alertEdit.show = false;
        }, 1600);
      }
    },
    'category.name': function categoryName(val, old) {
      if (val.length > 0) {
        if (this.tempType !== null && this.tempImage !== null) {
          this.disabledEdit = true;
        } else {
          this.disabledEdit = false;
        }
      } else {
        this.disabledEdit = false;
      }
    },
    'tempImage': function tempImage(val, old) {
      if (val !== null) {
        if (this.tempType !== null && this.category.name.length > null) {
          this.disabledEdit = true;
        } else {
          this.disabledEdit = false;
        }
      } else {
        this.disabledEdit = false;
      }
    },
    tempType: function tempType(val, old) {
      if (val !== null) {
        if (this.tempImage !== null && this.category.name.length > null) {
          this.disabledEdit = true;
        } else {
          this.disabledEdit = false;
        }
      } else {
        this.disabledEdit = false;
      }
    }
  },
  methods: {
    onFileChanged: function onFileChanged(f, x) {
      this.category_image = f[0];
      this.tempImage = URL.createObjectURL(f[0]);
      this.flagEditImage = true;
    },
    onSubmitEdit: function onSubmitEdit() {
      var _url = 'categories/' + this.category_id;

      this.disabledEdit = true;
      this.loadingEdit = true;

      var data = new FormData();
      data.append('name', this.category.name);
      data.append('type', this.tempType.value);
      if (this.flagEditImage) {
        data.append('category_image', this.category_image);
      }

      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      var _this = this;
      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4 && this.status === 200) {
          // console.log(JSON.parse(this.responseText))
          _this.loadingEdit = _this.disabledEdit = false;
          _this.backToUrl('categorias');
        }
      });
      xhr.open('PUT', _url);
      xhr.setRequestHeader('cache-control', 'no-cache');
      xhr.send(data);
    },
    fetchCategory: function fetchCategory() {
      var _this3 = this;

      this.categories = [];
      var _url = 'categories/' + this.category_id;
      this.$http({
        url: _url,
        method: 'GET'
      }).then(function (s) {
        var _data = s.data;
        _this3.category = _data;
        _this3.tempImage = _data.thumb_url;
        _this3.$nextTick(function () {
          _this3.responseCategory = true;
        });
      }, function (e) {
        // console.log('error', e)
        if (e.status === 401) {
          window.location = 'login';
        }
      });
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"bgTransition\">\n  <template v-if=\"!responseCategory\">\n    <div class=\"preload-app\">\n      <ui-progress-linear :show=\"!responseCategory\" color=\"black\"></ui-progress-linear>\n    </div>\n  </template>\n  <template v-else=\"\">\n    <div class=\"header-section h_contact\">\n      <h2 v-if=\"titleShow\" transition=\"slideLeft\">Editar Categoria</h2>\n    </div>\n\n    <section class=\"section-users\">\n      <div class=\"width-users clearfix table-responsive\">\n        <ui-alert :type=\"alertEdit.type\" :show=\"alertEdit.show\" :dismissible=\"false\">{{alertEdit.messages}}</ui-alert>\n        <div class=\"content-create-version\">\n          <ui-textbox type=\"text\" name=\"name\" placeholder=\"Nombre\" :value.sync=\"category.name\"></ui-textbox>\n          <ui-select name=\"type\" :options=\"category_type\" placeholder=\"Seleccione un tipo\" :value.sync=\"tempType\" :default=\"category.type\"></ui-select>\n          <img style=\"width: 100px; height: 100px; margin-right: 20px;\" :src=\"tempImage\">\n          <ui-file-upload name=\"category_image\" accept=\"image/*\" @changefile=\"onFileChanged\">Seleccione una imagen</ui-file-upload>\n\n          <div class=\"text-center\" style=\"color: #fff;\">\n            <ui-button button-type=\"button\" @click=\"backToUrl('categorias')\" text=\"Cancelar\" color=\"admin-primario\" :disabled=\"disabledCancel\" raised=\"\"></ui-button>\n\n            <ui-button button-type=\"submit\" @click=\"onSubmitEdit()\" text=\"Enviar\" color=\"admin-cuarto\" :loading=\"loadingEdit\" :disabled=\"!disabledEdit\" raised=\"\"></ui-button>\n          </div>\n        </div>\n      </div>\n    </section>\n  </template>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-5413b580", module.exports)
  } else {
    hotAPI.update("_v-5413b580", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}