'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classlist = require('./helpers/classlist');

var _classlist2 = _interopRequireDefault(_classlist);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var startRipple = function startRipple(eventType, event) {
  var holder = event.currentTarget;
  if (!holder) {
    return;
  }
  if (!_classlist2.default.has(holder, 'ui-ripple-ink')) {
    holder = holder.querySelector('.ui-ripple-ink');
    if (!holder) {
      return;
    }
  }
  var prev = holder.getAttribute('data-ui-event');
  if (prev && prev !== eventType) {
    return;
  }
  holder.setAttribute('data-ui-event', eventType);

  var rect = holder.getBoundingClientRect();
  var x = event.offsetX;
  var y;
  if (x !== undefined) {
    y = event.offsetY;
  } else {
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
  }
  var ripple = document.createElement('div');
  var max;
  if (rect.width === rect.height) {
    max = rect.width * 1.412;
  } else {
    max = Math.sqrt(rect.width * rect.width + rect.height * rect.height);
  }
  var dim = max * 2 + 'px';
  ripple.style.width = dim;
  ripple.style.height = dim;
  ripple.style.marginLeft = -max + x + 'px';
  ripple.style.marginTop = -max + y + 'px';
  // Activate/add the element
  ripple.className = 'ripple';
  holder.appendChild(ripple);
  setTimeout(function () {
    _classlist2.default.add(ripple, 'held');
  }, 0);

  var releaseEvent = eventType === 'mousedown' ? 'mouseup' : 'touchend';

  var release = function release() {
    document.removeEventListener(releaseEvent, release);

    _classlist2.default.add(ripple, 'done');

    // Larger than the animation duration in CSS
    setTimeout(function () {
      holder.removeChild(ripple);
      if (!holder.children.length) {
        _classlist2.default.remove(holder, 'active');
        holder.removeAttribute('data-ui-event');
      }
    }, 450);
  };
  document.addEventListener(releaseEvent, release);
};

var handleMouseDown = function handleMouseDown(e) {
  if (e.button === 0) {
    startRipple(e.type, e);
  }
};

var handleTouchStart = function handleTouchStart(e) {
  if (e.changedTouches) {
    for (var i = 0; i < e.changedTouches.length; ++i) {
      startRipple(e.type, e.changedTouches[i]);
    }
  }
};

exports.default = {
  name: 'ui-ripple-ink',
  props: {
    trigger: {
      type: Element,
      required: true
    }
  },

  watch: {
    trigger: function trigger() {
      this.initialize();
    }
  },

  ready: function ready() {
    this.initialize();
  },
  beforeDestory: function beforeDestory() {
    if (this.trigger) {
      this.trigger.removeEventListener('mousedown', handleMouseDown);
      this.trigger.removeEventListener('touchstart', handleTouchStart);
    }
  },


  methods: {
    initialize: function initialize() {
      if (this.trigger) {
        this.trigger.addEventListener('touchstart', handleTouchStart);
        this.trigger.addEventListener('mousedown', handleMouseDown);
      }
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-ripple-ink\"></div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-1876c667", module.exports)
  } else {
    hotAPI.update("_v-1876c667", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}