'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _disabled = require('./directives/disabled');

var _disabled2 = _interopRequireDefault(_disabled);

var _ReceivesTargetedEvent = require('./mixins/ReceivesTargetedEvent');

var _ReceivesTargetedEvent2 = _interopRequireDefault(_ReceivesTargetedEvent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'ui-checkbox',

    props: {
        name: String,
        model: {
            type: [Array, String, Boolean],
            required: true,
            twoWay: true
        },
        value: String,
        label: String,
        hideLabel: {
            type: Boolean,
            default: false
        },
        labelLeft: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data: function data() {
        return {
            active: false,
            initialValue: false
        };
    },


    computed: {
        isChecked: function isChecked() {
            if (this.value) {
                return this.model.indexOf(this.value) > -1;
            }

            return this.model;
        }
    },

    created: function created() {
        // Cache initial value for later reset
        this.initialValue = this.model;
    },


    events: {
        'ui-input::reset': function uiInputReset(id) {
            // Abort if reset event isn't meant for this component
            if (!this.eventTargetsComponent(id)) {
                return;
            }

            this.model = this.initialValue;
        }
    },

    methods: {
        focus: function focus() {
            this.active = true;
        },
        blur: function blur() {
            this.active = false;
        }
    },

    directives: {
        disabled: _disabled2.default
    },

    mixins: [_ReceivesTargetedEvent2.default]
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<label class=\"ui-checkbox\" :class=\"{\n        'disabled': disabled, 'checked': isChecked, 'active': active, 'label-left': labelLeft\n    }\">\n    <input class=\"ui-checkbox-input\" type=\"checkbox\" :name=\"name\" @focus=\"focus\" @blur=\"blur\" :value=\"value ? value : null\" v-model=\"model\" v-disabled=\"disabled\">\n\n    <div class=\"ui-checkbox-checkmark\">\n        <div class=\"ui-checkbox-focus-ring\"></div>\n    </div>\n\n    <div class=\"ui-checkbox-label-text\" v-if=\"!hideLabel\">\n        <slot>\n            <span v-text=\"label\"></span>\n        </slot>\n    </div>\n</label>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-3b81d2fc", module.exports)
  } else {
    hotAPI.update("_v-3b81d2fc", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}