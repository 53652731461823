'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _disabled = require('./directives/disabled');

var _disabled2 = _interopRequireDefault(_disabled);

var _ReceivesTargetedEvent = require('./mixins/ReceivesTargetedEvent');

var _ReceivesTargetedEvent2 = _interopRequireDefault(_ReceivesTargetedEvent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-switch',
  props: {
    name: String,
    value: {
      type: Boolean,
      required: true,
      twoWay: true
    },
    label: String,
    hideLabel: {
      type: Boolean,
      default: false
    },
    labelLeft: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      initialValue: false
    };
  },
  created: function created() {
    this.initialValue = this.value;
  },

  events: {
    'ui-input::reset': function uiInputReset(id) {
      if (!this.eventTargetsComponent(id)) {
        return;
      }
      this.value = this.initialValue;
    }
  },
  directives: {
    disabled: _disabled2.default
  },
  mixins: [_ReceivesTargetedEvent2.default]
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<label class=\"ui-switch\" :class=\"{ 'checked': value, 'disabled': disabled, 'label-left': labelLeft }\">\n  <div class=\"ui-switch-container\">\n    <input class=\"ui-switch-input\" type=\"checkbox\" :name=\"name\" :id=\"id\" v-model=\"value\" v-disabled=\"disabled\">\n    <div class=\"ui-switch-track\"></div>\n    <div class=\"ui-switch-thumb\"></div>\n    <div class=\"ui-switch-focus-ring\"></div>\n  </div>\n  <div class=\"ui-switch-label-text\" v-if=\"!hideLabel\">\n    <slot>\n      <span v-text=\"label\"></span>\n    </slot>\n  </div>\n</label>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-5846619a", module.exports)
  } else {
    hotAPI.update("_v-5846619a", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}