'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _UiIcon = require('./UiIcon.vue');

var _UiIcon2 = _interopRequireDefault(_UiIcon);

var _UiIconButton = require('./UiIconButton.vue');

var _UiIconButton2 = _interopRequireDefault(_UiIconButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-alert',

  props: {
    show: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'info' // 'info', 'success', 'warning', or 'error'
    },
    text: String,
    icon: String,
    hideIcon: {
      type: Boolean,
      default: false
    },
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    iconName: function iconName() {
      if (this.icon) {
        return this.icon;
      }
      var icon = this.type;
      if (icon === 'success') {
        icon = 'check_circle';
      }
      return icon;
    }
  },
  methods: {
    close: function close() {
      this.show = false;
      this.$dispatch('dismissed');
    }
  },
  components: {
    UiIcon: _UiIcon2.default,
    UiIconButton: _UiIconButton2.default
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-alert\">\n  <div class=\"ui-alert-body\" :class=\"[type]\" role=\"alert\" v-show=\"show\" transition=\"ui-alert-toggle\">\n    <ui-icon class=\"ui-alert-icon\" :icon=\"iconName\" v-if=\"!hideIcon\"></ui-icon>\n    <div class=\"ui-alert-text\">\n      <slot>\n        <span v-text=\"text\"></span>\n      </slot>\n    </div>\n    <ui-icon-button class=\"ui-alert-close-button\" type=\"clear\" icon=\"\" aria-label=\"Close\" @click=\"close\" v-if=\"dismissible\"></ui-icon-button>\n  </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-135e05ad", module.exports)
  } else {
    hotAPI.update("_v-135e05ad", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}