'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _UiIcon = require('./UiIcon.vue');

var _UiIcon2 = _interopRequireDefault(_UiIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-select-option',
  props: {
    option: {
      type: [String, Object],
      required: true
    },
    partial: {
      type: String,
      default: 'ui-select-simple' // 'ui-select-simple', 'ui-select-image'
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    keys: {
      type: Object,
      default: function _default() {
        return {
          text: 'text',
          value: 'value',
          image: 'image'
        };
      }
    }
  },
  computed: {
    icon: function icon() {
      return this.selected ? 'check_box' : 'check_box_outline_blank';
    }
  },
  components: {
    UiIcon: _UiIcon2.default
  },

  partials: {
    'ui-select-simple': '\n        <li class="ui-select-item-text" v-text="option[keys.text] || option"></li>\n    ',

    'ui-select-image': '\n        <div\n            class="ui-select-item-image"\n            :style="{ \'background-image\': \'url(\' + option[keys.image] + \')\' }"\n        ></div>\n\n        <div class="ui-select-item-text" v-text="option[keys.text]"></div>\n    '
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<li class=\"ui-select-option\" :class=\"{ highlighted: highlighted, selected: selected }\">\n  <div class=\"ui-select-option-content\" :class=\"[partial]\">\n    <partial :name=\"partial\"></partial>\n  </div>\n  <ui-icon class=\"ui-select-option-checkbox\" :icon=\"icon\" v-if=\"showCheckbox\"></ui-icon>\n</li>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-8a049660", module.exports)
  } else {
    hotAPI.update("_v-8a049660", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}