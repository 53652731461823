Number.prototype.padLeft = function(base,chr){
  var  len = (String(base || 10).length - String(this).length)+1;
  return len > 0? new Array(len).join(chr || '0')+this : this;
}

import UiButton from '../material/UiButton.vue'
import UiTextbox from '../material/UiTextbox.vue'
import UiProgressLinear from '../material/UiProgressLinear.vue'
export default {
  route: {
    activate () {
      this.titleShow = true
    },
    canDeactivate (transition) {
      this.titleShow = false
      transition.next()
    }
  },
  components: {
    UiButton,
    UiTextbox,
    UiProgressLinear
  },
  data () {
    return {
      base_url: 'http://192.168.1.115:8000/',
      titleShow: false
    }
  },
  filters: {
    formatFull (val) {
      let d = new Date(val)
      return [(d.getMonth()+1).padLeft(),
               d.getDate().padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft(),
               d.getSeconds().padLeft()].join(':');
    },
    formatDate (val) {
      return this.yymmdd(new Date(val))
    }
  },
  methods: {
    yymmdd (_date) {
      var mm = _date.getMonth() + 1
      var dd = _date.getDate()

      return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        _date.getFullYear()
      ].join('-')
    },
    isDataNull (_val) {
      return (_val === undefined || _val == null || _val.length <= 0) ? 1 : 0
    },
    isObject (obj) {
      return obj !== null && typeof obj === 'object'
    },
    isEmpty (obj) {
      if (obj == null) return true

      if (obj.length > 0) return false
      if (obj.length === 0) return true

      if (typeof obj !== 'object') return true

      for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false
      }
      return true
    },
    backToUrl (_name, _params = '') {
      if (_params.length > 0) {
        this.$router.go({
          name: _name,
          params: {
            id: _params
          }
        })
      } else {
        this.$router.go({
          name: _name
        })
      }
    }
  }
}
