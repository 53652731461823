'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Navbar = require('./Navbar.vue');

var _Navbar2 = _interopRequireDefault(_Navbar);

var _globals = require('../mixins/globals');

var _globals2 = _interopRequireDefault(_globals);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'Base',
  mixins: [_globals2.default],
  components: {
    Navbar: _Navbar2.default
  },
  data: function data() {
    return {
      user: {},
      viewOption: false,
      activeModal: false,
      modalOnOff: false,
      load_user: false,
      activeAccount: false
    };
  },
  ready: function ready() {
    this.fetchMe();
  },

  methods: {
    fetchMe: function fetchMe() {
      var _this = this;

      var _url = 'users/me';
      this.$http.get(_url).then(function (s) {
        _this.user = s.body;
        /*
        switch(this.user.rol){
          case "ADMIN":
          this.backToUrl("devices");
          break;
          case "DEVICE_MASTER":
          this.backToUrl("devices");
          break;
          case "STICKER_MASTER":
          this.backToUrl("categorias");
          break;
          case "OTA_MASTER":
          this.backToUrl("devices");
          break;
        }
        */
      }).catch(function (e) {
        if (e.status === 401) {
          window.location = 'login';
        }
      }).finally(function () {
        _this.load_user = false;
      });
    },
    logout: function logout() {
      var _url = '/logout';

      this.$http.get(_url).then(function (s) {
        location.reload();
      }).catch(function (e) {
        if (e.status === 401) {
          window.location = 'login';
        }
      });
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"container-layout\">\n  <section class=\"content-layout\">\n    <navbar :user=\"user\"></navbar>\n    <section class=\"content-main\" style=\"padding-bottom:40px\">\n      <header class=\"header-user\">\n        <div class=\"clearfix\">\n          <ui-button button-type=\"button\" text=\"Cerrar Sesión\" style=\"margin-top: 8px\" @click=\"logout()\"></ui-button>\n          <div class=\"b-right\">\n            <div class=\"picture-user\">\n              <span class=\"name-user\">{{user.email}}</span>                \n            </div>\n            <div class=\"picture-user\">\n              <img v-bind:src=\"user.photo_url\">\n            </div>\n          </div>\n        </div>\n      </header>\n      <template v-if=\"!load_user\">\n        <router-view :user=\"user\" transition=\"fade\"></router-view>\n      </template>\n    </section>\n  </section>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-32e60fa9", module.exports)
  } else {
    hotAPI.update("_v-32e60fa9", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}