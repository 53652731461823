;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<iframe style=\"border:0;width:100% !important;height:100% !important\" src=\"https://datastudio.google.com/embed/reporting/12EoR8mGlS7VCuCSe_Tz9mmaP0hhf7vdY/page/Gg3\" frameborder=\"0\" allowfullscreen=\"\">\n</iframe>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-227095f1", module.exports)
  } else {
    hotAPI.update("_v-227095f1", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}