'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'ui-fileupload',

  props: {
    name: {
      type: String,
      required: true
    },
    label: String,
    accept: String,
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary' // 'primary' or 'secondary'
    },
    color: {
      type: String,
      default: 'default' // 'default', 'primary', 'accent'
    },
    size: {
      type: String,
      default: 'normal' // 'small', 'normal', 'large'
    },
    raised: {
      type: Boolean,
      default: true
    },
    iconPosition: {
      type: String,
      default: 'left' // 'left' or 'right'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: function data() {
    return {
      isActive: false,
      hasSelection: false,
      hasMultiple: false,
      displayText: '',
      focusRing: {
        top: 0,
        left: 0,
        size: 0,
        initialized: false
      }
    };
  },

  computed: {
    classes: function classes() {
      return ['ui-fileupload--type-' + this.type, 'ui-fileupload--color-' + this.color, 'ui-fileupload--icon-position-' + this.iconPosition, 'ui-fileupload--size-' + this.size, { 'is-active': this.isActive }, { 'is-multiple': this.hasMultiple }, { 'is-raised': this.raised }, { 'is-disabled': this.disabled }];
    },
    placeholder: function placeholder() {
      if (this.label) {
        return this.label;
      }
      return this.multiple ? 'Choose files' : 'Choose a file';
    },
    focusRingStyle: function focusRingStyle() {
      return {
        height: this.focusRing.size + 'px',
        width: this.focusRing.size + 'px',
        top: this.focusRing.top + 'px',
        left: this.focusRing.left + 'px'
      };
    }
  },

  methods: {
    onFocus: function onFocus(e) {
      this.isActive = true;
      this.$emit('focus', e);

      if (!this.focusRing.initialized) {
        this.initializeFocusRing();
      }
    },
    onBlur: function onBlur(e) {
      this.isActive = false;
      this.$emit('blur', e);
    },
    onChange: function onChange(e) {
      var _this = this;

      var displayText = void 0;
      var input = this.$els.input;
      e.preventDefault();

      // if (input.files && input.files.length > 1) {
      //   displayText = `${input.files.length} files selected`
      // } else {
      displayText = e.target.value.split('\\').pop();
      //}

      if (displayText) {
        this.hasSelection = true;
        this.displayText = displayText;
        this.hasMultiple = input.files.length > 1;

        this.$nextTick(function () {
          return _this.refreshFocusRing();
        });
      }
      this.$emit('changefile', input.files, e);
    },
    initializeFocusRing: function initializeFocusRing() {
      this.refreshFocusRing();
      this.focusRing.initialized = true;
    },
    refreshFocusRing: function refreshFocusRing() {
      var bounds = {
        width: this.$el.clientWidth,
        height: this.$el.clientHeight
      };
      this.focusRing.size = bounds.width - 16; // 8px of padding on left and right
      this.focusRing.top = -1 * (this.focusRing.size - bounds.height) / 2;
      this.focusRing.left = (bounds.width - this.focusRing.size) / 2;
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<label class=\"ui-fileupload\" ref=\"label\" :class=\"classes\" style=\"margin-bottom: 15px;\">\n  <input v-el:input=\"\" class=\"ui-fileupload__input\" type=\"file\" :accept=\"accept\" :disabled=\"disabled\" :name=\"name\" :required=\"required\" @change=\"onChange\">\n  <div class=\"ui-fileupload__content\">\n    <div class=\"ui-fileupload__icon\">\n      <span class=\"ui-icon material-icons\">\n        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\">\n          <path d=\"M5.016 18h13.969v2.016H5.016V18zM9 15.984v-6H5.016L12 3l6.984 6.984H15v6H9z\"></path>\n        </svg>\n      </span>\n    </div>\n    <span v-if=\"hasSelection\" class=\"ui-fileupload__display-text\">{{ displayText }}</span>\n    <slot v-else=\"\">{{ placeholder }}</slot>\n  </div>\n  <div class=\"ui-fileupload__focus-ring\" :style=\"focusRingStyle\"></div>\n</label>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-bb9ddac8", module.exports)
  } else {
    hotAPI.update("_v-bb9ddac8", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}